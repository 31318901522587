import { normalizeBackgroundColor } from '~/lib/storyblok'
import type { Asset, EdgeStyles, CustomColor, Palette } from './shared'

export interface HeroPlpBlock {
  component: 'hero-plp-block'
  edge: EdgeStyles
  body: string
  image: Asset
  imageAltText: string
  background_color: (Palette | CustomColor)[]
  font_color: string
}


export function normalizePlpHero(blok?: HeroPlpBlock) {

    return {
      body: blok?.body,
      edge : blok?.edge,
      image :blok?.image.filename,
      imageAltText: blok?.imageAltText,
      backgroundColor: normalizeBackgroundColor(blok?.background_color?.[0]),
      fontColor: blok?.font_color ?? 'black',
    }
  }
